<template>
  <div>
      <h1
      class="display-3 d-flex align-items-center font-weight-light overflow-hidden position-absolute text-white"
      style="height: 66px; margin: 0 0 0 30px; top: 0; z-index: 1"
    >
      <h6 class="h2 text-white d-inline-block mb-0">
        <i class="fa fa-link"></i> Laporan Manual Data Satu Minggu
      </h6>
    </h1>
    
    <base-header class="pb-6">
        <b-row>
          <b-col xl="12" md="12">
            <b-card style="position: relative;overflow: hidden;">

              <h3>Cari Berdasarkan</h3>

              <div class="d-flex justify-content-start" style="position: relative;z-index: 999;">
                <!-- status pendaftaran -->
                <div style="display: flex;flex-direction: column;width: 180px; margin-right: 10px;">
                  <label><small>Status Pendaftaran</small></label>
                  <el-select class="select-primary pagination-select" v-model="filterStatusPendaftaran"  placeholder="Status Pendaftaran">
                  <el-option
                      class="select-primary"
                      v-for="item in selectOptionStatus"
                      :key="item.val"
                      :label="item.name"
                      :value="item.val"
                  >
                  </el-option>
                  </el-select>
                </div>

                <!-- Tanggal -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;">
                  <label><small>Tanggal</small></label>
                  <base-input name="Tanggal">
                    <flat-picker
                      placeholder="Filter Tanggal"
                      :config="provideSelect.flatPickerConfig"
                      class="form-control datepicker"
                      v-model="userInput.tanggal"
                      style="background:white;"
                    />
                  </base-input>
                </div>

                <!-- Jenis Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Jenis Industri</small></label>
                  <base-input>
                    <el-select filterable placeholder="Jenis Industri" v-model="userInput.companyType" @change="resetIndustry()">
                      <el-option value="">Semua Jenis Industri</el-option>
                      <el-option
                        v-for="option, idx in provideSelect.companyType"
                        :key="idx"
                        :label="option.name"
                        :value="option.id"
                      ></el-option>
                    </el-select>
                  </base-input>
                </div>

                <!-- Provinsi -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Provinsi</small></label>
                  <base-input name="Provinsi" >
                      <el-select
                        v-model="form.umum.compProvince"
                        @change="getCityDepentOnProvince(form.umum.compProvince)" filterable>
                        <el-option
                        label="Semua Provinsi"
                        :value=null
                        >
                        </el-option>
                        <el-option
                          v-for="option in provideSelect.provinsi"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'">
                  <label><small>Kabupaten / Kota</small></label>
                  <base-input name="Kabupaten / Kota" >
                      <el-select
                        filterable
                        v-model="form.umum.compCity"
                        >
                        <el-option
                          v-for="option in provideSelect.kabupatenKota"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Nama Industri -->
                <div style="display: flex;flex-direction: column;width: 180px;margin-right: 10px;" v-if="log_as == 'admin'" >
                  <label><small>Nama Industri</small></label>
                  <base-input name="Nama Industri" >
                      <el-select
                        filterable
                        v-model="userInput.companyName"
                        @change="getMyCompliencePoint"
                        
                        >
                        <el-option value="">Semua</el-option>
                        <el-option
                          v-for="option in provideSelect.companyName"
                          :key="option.id"
                          :label="option.name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>

                <!-- Titik Penaatan -->
                <div style="display: flex;flex-direction: column;width: 180px;">
                  <label><small>Titik Penaatan</small></label>
                  <base-input>
                      <el-select filterable placeholder="Titik Penaatan" v-model="userInput.titikPenaatan">
                        <el-option
                          v-for="option in listDataCompliencePoints"
                          :key="option.id"
                          :label="option.spot_name"
                          :value="option.id"
                        ></el-option>
                      </el-select>
                  </base-input>
                </div>
              </div>

              <base-button size="md" type="primary" @click="getBaseOnStatus"> Tampilkan </base-button>
              <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -100px;z-index: 50;opacity: 0.1;">
            </b-card>
          </b-col>
        </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
            <b-row>
              <b-col lg="12" cols="12" style="display: flex;justify-content: flex-end;" v-if="log_as !== 'admin'">
                <!-- <button class="btn btn-secondary">Download</button> -->
                <button class="btn btn-primary" @click="openModalAdd">+ Buat Laporan Baru</button>
              </b-col>
            </b-row>
            </template>

            <section id="mytable">
              <el-table
                :data="listData"
                row-key="id"
                header-row-class-name="thead-light"
              >

                <el-table-column label="No" type="index" width="100" />

                  <el-table-column min-width="120px"  label="Nama Industri" v-if="log_as == 'admin'">
                    <div slot-scope="action">
                      {{ action.row.comp_name }}
                    </div>
                  </el-table-column>

                  <el-table-column min-width="120px"  label="Nama Titik Penaatan">
                    <div slot-scope="action">
                      {{ action.row.spot_name }}
                    </div>
                  </el-table-column>

                  
              

                <el-table-column min-width="110px"  label="Tanggal Kejadian">
                  <div slot-scope="action">
                    {{ formatDate(action.row.start_date) }}
                  </div>
                </el-table-column>

                <el-table-column min-width="100px"  label="Tanggal Berakhir">
                  <div slot-scope="action">
                   {{ formatDate(action.row.end_date) }}
                  </div>
                </el-table-column>

                <el-table-column min-width="90px"  label="Minggu Ke">
                  <div slot-scope="action">
                    {{ getQuarter(action.row.tanggal_mulai_kejadian) }}
                  </div>
                </el-table-column>

                <el-table-column min-width="100px"  label="Dokumen SHU">
                  <div slot-scope="action">
                    <a :href="`https://${action.row.shu_path}`" target="blank">
                    <button class="btn btn-primary btn-sm">Unduh</button>
                    </a>
                  </div>
                </el-table-column>

                <el-table-column min-width="170px"  label="Status">
                  <div slot-scope="action">
                    <div :style="getStyleObject(action.row.status)" class="text-white p-1 text-center rounded">
                      {{ action.row.status }}
                    </div>
                  </div>
                </el-table-column>


                <el-table-column min-width="250px"  label="AKSI">
                  <div slot-scope="action">
                      <base-button
                      @click="view(action.row.id, 'create')"
                      type="secondary"
                      size="sm"
                      icon
                      >
                      RINCIAN
                      </base-button>

                      <base-button
                      v-if="log_as !== 'admin' "
                      @click="view(action.row.id, 'update')"
                      type="warning"
                      size="sm"
                      icon
                      >
                      UBAH
                      </base-button>

                      <base-button
                      v-if="log_as !== 'admin' "
                      @click="deleteData(action.row.id)"
                      type="danger"
                      size="sm"
                      icon
                      >
                      HAPUS
                      </base-button>

                      <base-button
                      v-if="log_as == 'admin' "
                      @click="validationAction(action.row.id)"
                      type="dark"
                      size="sm"
                      icon
                      >
                      Validasi
                      </base-button>
                  </div>
                </el-table-column>
              </el-table>

              <div class="py-3">

                  <el-select
                    class="select-primary pagination-select mr-3"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                    @change="changeSizePage()"
                    >
                    <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                    </el-option>
                  </el-select>

                  <small class="card-category">
                  Showing {{  1 }} to {{ listData.length }} of {{ pagination.total }} entries
                  </small>
              </div>

              <div style="display: flex;justify-content: flex-end;">
                <base-pagination
                    class="pagination-no-border"
                    :current="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    @change="paginationChanged($event)"
                >
                </base-pagination>
              </div>
            </section>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- Modal Add -->
    <Modal
      :show="showAddModal"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      size="lg"
    >
      <template v-slot:header>
        <div class="modal-title">
          <h3 class="mdl-title">Tambah Laporan Manual</h3>
        </div>
      </template>
      <div class="px-4 border-top pt-4">

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(submitNow)">
            <!-- Pilih Titik Penaatan -->
           <div class="form-group row">
             <label class="col-lg-12 col-form-label form-control-label">Pilih Titik Penaatan</label>
             <div class="col-lg-12">
               <base-input name="Titik Penaatan" rules="required">
                 <el-select v-model="userInput.pilihTitikPenaatan" @change="getInfoSensor(userInput.pilihTitikPenaatan)">
                   <el-option
                     v-for="(item, index) in listDataCompliencePoints"
                     :key="index"
                     :label="item.spot_name"
                     :value="item.id"
                   />
                 </el-select>
               </base-input>
             </div>
           </div>
   
   
           
           <div class="form-group row mt--5">
               <label class="col-12 col-form-label form-control-label">Dokumen SHU</label>
               <div class="col-12">
                 <base-input :key="fileSHU">
                   <file-input
                     accept="application/pdf"
                     ref="report_weekly_manual_shu_file"
                     @change="uploadFile('report_weekly_manual_shu_file', 'report_weekly_manual_shu_file')" />
                 </base-input>
               </div>
           </div>
   
           <!-- Tanggal Kejadian -->
           <div class="form-group row mt--5">
             <label  class="col-lg-12 col-form-label form-control-label">Tanggal Kejadian</label>
             <div class="col-12">
               <base-input name="Tanggal Kejadian" rules="required">
                 <flat-picker :config="provideSelect.flatPickerConfig" class="form-control datepicker" v-model="userInput.tanggalKejadian" />
               </base-input>
             </div>
           </div>


           
           <h3>Isi Nilai Parameter</h3>
           <div class="form-group row">
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">pH</label>
               <base-input
                 name="Ph"
                 rules="required"
                 v-model="userInput.ph"
                 :disabled="phDisable"
               ></base-input>
             </div>
   
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">COD</label>
               <base-input
                 name="COD"
                 rules="required"
                 v-model="userInput.cod"
                 :disabled="codDisable"
               ></base-input>
             </div>
   
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">TSS</label>
               <base-input
                 name="TSS"
                 rules="required"
                 v-model="userInput.tss"
                 :disabled="tssDisable"
               ></base-input>
             </div>
   
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">NH3N</label>
               <base-input
                 name="NH3N"
                 rules="required"
                 v-model="userInput.nh3n"
                 :disabled="nh3nDisable"
               ></base-input>
             </div>
   
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">Debit</label>
               <base-input
                 name="Debit"
                 rules="required"
                 v-model="userInput.debit"
                 :disabled="debitDisable"
               ></base-input>
             </div>
           </div>

           <div class="form-group row mt-4">
             <div class="col-lg-12">
               <div class="float-right">
                 <button class="btn btn-primary" type="submit">Tambah</button>
                 <button class="btn btn-danger" @click="closeModalAdd">Tutup</button>
               </div>
             </div>
           </div>
          </b-form>
        </validation-observer>


        
      </div>
    </Modal>

    <!-- Modal Update -->
    <Modal
      :show="showUpdateModal"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="closeModalUpdate"
      size="lg"
    >
      <template v-slot:header>
        <div class="modal-title">
          <h3 class="mdl-title">Ubah Laporan Manual</h3>
        </div>
      </template>
      <div class="px-4 border-top pt-4">

        <validation-observer v-slot="{handleSubmit}" ref="formValidator">
          <b-form role="form" @submit.prevent="handleSubmit(submitUpdate)">
            <!-- Pilih Titik Penaatan -->
           <div class="form-group row">
             <label class="col-lg-12 col-form-label form-control-label">Pilih Titik Penaatan</label>
             <div class="col-lg-12">
               <base-input name="Titik Penaatan" rules="required">
                 <el-select v-model="detailView.loggerId">
                   <el-option
                     v-for="(item, index) in listDataCompliencePoints"
                     :key="index"
                     :label="item.spot_name"
                     :value="item.id"
                   />
                 </el-select>
               </base-input>
             </div>
           </div>
   
           <div class="form-group row mt--5">
               <label class="col-12 col-form-label form-control-label">Dokumen SHU</label>
               <div class="col-12">
                 <base-input :key="fileSHU">
                   <file-input
                     accept="application/pdf"
                     ref="report_weekly_manual_shu_file_update"
                     @change="uploadFile('report_weekly_manual_shu_file_update', 'report_weekly_manual_shu_file_update')" />
                 </base-input>
                 <small><a :href="`https://${detailView.file}`" target="blank">Lihat File Sebelumnya</a></small>
               </div>
           </div>
   
           <!-- Tanggal Kejadian -->
           <div class="form-group row">
             <label  class="col-lg-12 col-form-label form-control-label">Tanggal Kejadian</label>
             <div class="col-12">
               <base-input name="tanggal kejadian" rules="required">
                 <flat-picker :config="provideSelect.flatPickerConfig" class="form-control datepicker" v-model="detailView.tanggalKejadian"/>
               </base-input>
             </div>
           </div>
   
        
   
           
           <h3>Isi Nilai Parameter</h3>
           <div class="form-group row">
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">pH</label>
               <base-input
                 rules="required"
                 name="Ph"
                 v-model="detailView.ph"
                 :disabled="phDisable"
               ></base-input>
             </div>
   
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">COD</label>
               <base-input
                 rules="required"
                 name="COD"
                 v-model="detailView.cod"
                 :disabled="codDisable"
               ></base-input>
             </div>
   
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">TSS</label>
               <base-input
                 rules="required"
                 name="TSS"
                 v-model="detailView.tss"
                 :disabled="tssDisable"
               ></base-input>
             </div>
   
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">NH3N</label>
               <base-input
                 rules="required"
                 name="NH3N"
                 v-model="detailView.nh3n"
                 :disabled="nh3nDisable"
               ></base-input>
             </div>
   
             <div class="col-lg-2">
               <label class="col-form-label form-control-label">Debit</label>
               <base-input
                 rules="required"
                 name="Debit"
                 v-model="detailView.debit"
                 :disabled="debitDisable"
               ></base-input>
             </div>
           </div>
           <div class="form-group row mt-4">
             <div class="col-lg-12">
               <div class="float-right">
                 <button class="btn btn-primary" type="submit">Ubah Data</button>
                 <button class="btn btn-danger" type="button" @click="closeModalUpdate">Tutup</button>
               </div>
             </div>
           </div>
          </b-form>
        </validation-observer>


        
      </div>
    </Modal>

    <!-- Modal Rincian -->
    <Modal
      :show="showViewModal"
      modalClasses="modal-dialog-scrollable"
      headerClasses="pb-0"
      @close="closeModalView"
      size="xl"
    >
      <template v-slot:header>
        <div class="modal-title">
          <h2>Detail Laporan Manual</h2>
        </div>
      </template>
      <div class="border-top pt-4">
        <div class="form-group row">
          <p class="col-3 col-form-label form-control-label pt-0">
            Nama Industri
          </p>
          <p class="col-9">{{ detailView.company_name }}</p>
        </div>
        <div class="form-group row">
          <p class="col-3 col-form-label form-control-label pt-0">Logger ID</p>
          <p class="col-9">{{ detailView.loggerId }}</p>
        </div>
        <div class="form-group row">
          <p class="col-3 col-form-label form-control-label pt-0">Dokumen SHU</p>
          <div class="col-9">
            <a
              :href="`https://${detailView.file}`"
              target="blank"
              class="rounded bg-primary px-2 py-1 text-white"
              >Unduh</a
            >
          </div>
        </div>
        <div class="form-group row">
          <p class="col-3 col-form-label form-control-label pt-0">
            Tanggal Kejadian
          </p>
          <p class="col-9">
           
            {{ formatDate(detailDateStart) }}
          </p>
        </div>
        <div class="form-group row">
          <p class="col-3 col-form-label form-control-label pt-0">
            Tanggal Berakhir
          </p>
          <p class="col-9">
            {{ formatDate(detailDateEnd) }}
          </p>
        </div>
        <div class="form-group row">
          <p class="col-3 col-form-label form-control-label pt-0">Minggu ke-</p>
          <p class="col-9">{{ getQuarter(detailDateStart) }}</p>
        </div>
        <div class="form-group row">
          <p class="col-3 col-form-label form-control-label pt-0">Status</p>
          <div class="col-9">
            <span
              v-if="detailView.status == 'Menunggu Validasi'"
              class="border border-warning rounded-lg px-2 py-1 text-warning"
            >
              Menunggu Validasi
            </span>
            <span
              v-if="detailView.status == 'Diterima'"
              class="border border-success rounded-lg px-2 py-1 text-success"
            >
              Diterima
            </span>
            <span
              v-if="detailView.status == 'Ditolak'"
              class="border border-danger rounded-lg px-2 py-1 text-danger"
            >
              Ditolak
            </span>
          </div>
        </div>
        <hr />
        <p class="font-weight-bold mt-4 mb-2">Nilai Parameter</p>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" v-if="detailView.ph !== null">pH</th>
                <th scope="col" v-if="detailView.cod !== null">COD</th>
                <th scope="col" v-if="detailView.tss !== null">TSS</th>
                <th scope="col" v-if="detailView.nh3n !== null">NH3N</th>
                <th scope="col" v-if="detailView.debit !== null">Debit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-if="detailView.ph !== null">{{ detailView.ph }}</td>
                <td v-if="detailView.cod !== null">{{ detailView.cod }}</td>
                <td v-if="detailView.tss !== null">{{ detailView.tss }}</td>
                <td v-if="detailView.nh3n !== null">{{ detailView.nh3n }}</td>
                <td v-if="detailView.debit !== null">{{ detailView.debit }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <ValidationManualReport :data="validationHistory" />

        <div class="form-group row mt-4">
          <div class="col-lg-12">
            <div class="float-right">
              <button class="btn btn-primary" @click="closeModalView">Tutup</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- modal of Validation-->
    <Modal
        :show="showAnnoncementModalValidation"
        modalContentClasses="announcement-modal-backgrounds"
        modalClasses="modal-dialog-scrollable"
        @close="showAnnoncementModalValidation = false"
        headerClasses="pb-0"
        size="xl">
        <template v-slot:header>
            <div class="modal-title">
                <p class="mdl-title">Validasi Laporan Manual Satu Minggu</p>
            </div>
        </template>
        <!-- ============================================================================== -->
        <div class="px-4 border-top pt-4">
          <template>
            <div class="form-group row">
              <p class="pl-3 col-form-label form-control-label"></p>
              <div class="col-lg-12">
                <html-editor v-model="verifyNote"></html-editor>
              </div>
            </div>
            <div class="mdl-btn-wrap mb-6">
                <button class="mdl-btn mdl-btn-reject" @click="accSparing('Ditolak')">Tolak</button>
                <button class="mdl-btn mdl-btn-accept" @click="accSparing('Diterima')">Setujui</button>
            </div>
          </template>
          <div>
            <ValidationManualReport :data="validationHistory"  />
          </div>
        </div>
    </Modal>

    <!-- Loading Screen -->
    <Modal
      :show="isLoading"
      modalContentClasses="popup-modal-backgrounds"
      modalClasses="modal-dialog-scrollable"
      size="lg"
      style="background-color: rgba(0, 0, 0, 0.8)"
    >
      <div>
        <div id="announcement" class="rounded overflow-hidden mdl-contentpopups">
          <div class="p-4">
            <div class="row">
              <div class="col-lg-12 text-center" style="position: relative;">
                <img src="img/brand/img-logo-color.png" alt="">
                <img src="img/brand/klhk.png" alt="" style="position: absolute;right: 0;top: -200px;opacity: 0.2;">
                <h1>Menyiapkan Data...</h1>
                <h5>Harap tunggu beberapa saat</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import API from '../../../api/base_url'
import { Select, Option, Table, TableColumn  } from "element-ui";
import BaseHeader from "@/components/BaseHeader";
import DataSparingChart from "@/components/Charts/DataSparingChart.vue";
import FileInput from "@/components/Inputs/FileInput";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from 'moment'
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationManualReport from '../../Tables/ValidationManualReport.vue';

// new
import Modal from "@/components/Modal.vue";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import ShortcutButtonsPlugin from "shortcut-buttons-flatpickr";
import html2pdf from "html2pdf.js";

export default {
components: {
  FileInput,
  flatPicker,
  BaseHeader,
  DataSparingChart,
  [Select.name]: Select,
  [Option.name]: Option,
  [Table.name]: Table,
  [TableColumn.name]: TableColumn,
  Modal,
  HtmlEditor,
  ValidationManualReport
},
mounted() {
    this.log_as = localStorage.getItem("as");
    this.getData()
    this.getCompanyType()
    this.getCompanyName()
    this.getProvince()
    this.getCompliencePoint()
},
data() {
  return {
    feedBackUploadDokumenSHU:"",
    phDisable:true,
    codDisable:true,
    tssDisable:true,
    nh3nDisable:true,
    debitDisable:true,
    filterStatusPendaftaran: "",
    selectOptionStatus: [
      {
        val:"",
        name:"Semua"
      },
      {
        val: "Menunggu Validasi",
        name:"Menunggu"
      },
      {
        val: "Diterima",
        name:"Disetujui"
      },
      {
        val: "Ditolak",
        name:"Ditolak"
      },
    ],
    detailDateStart:"",
    detailDateEnd:"",
    getIdValidation: "",
    verifyNote:"",
    histories:[],
    showAnnoncementModalValidation:false,
    log_as:"",
    detailView: {
      id:"",
      ph:"0",
      cod:"0",
      tss:"0",
      nh3n:"0",
      debit:"0",
      tanggalKejadian: "",
      tanggalAkhirKejadian: "",
      file:"",
      loggerId: "",
      status:"",
      company_name:""
    },
    validationHistory: [],
    listDataCompliencePoints: [],
    showAddModal:false,
    showUpdateModal:false,
    showViewModal:false,
    listData: [],
    pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50],
        total: 1
    },
    keyword:"",
    status:"",
    payloadForm: new FormData(),
    isLoading: false,
    fileSHU:0,
    pdfName:"",
      listDataDownload: [],
      listDataCompliencePoints: [],
      filterStatusPendaftaran: "",
      userInput: {
      pilihTitikPenaatan: "",
      tanggalKejadian: "",
      ph:0,
      cod:0,
      tss:0,
      nh3n:0,
      debit:0,
      tanggal: "",
      registrationType:"SP",
      titikPenaatan:"",
      companyType:"",
      companyName:"",
      provinsi:[],
      kabupatenKota:[],
      regency:[]
      },
      provideSelect: {
        flatPickerConfig: {
          allowInput: true,
          altInput: true,
          altFormat: "d-m-Y H:i",
          enableTime: false,
          time_24hr: true,

          defaultHour: 0,
          mode: "range",
          onChange: function (selectedDates, dateStr, instance) {
        // if (selectedDates.length > 1) {
            // var range = instance.formatDate(selectedDates[1], 'U') - instance.formatDate(selectedDates[0], 'U');
            // range = range / 86400;

            //     if(range != 6)
            //     {
            //         Swal.fire('Data harus 1 Minggu','','error')
            //         instance.clear()
            //     }


              
              if (selectedDates.length > 1) {
                const startDate = moment(selectedDates[0])
                const endDate = moment(startDate).add(1, 'week').endOf('day');
                instance.setDate([startDate.toDate(), endDate.toDate()]);
              }
            
            // }
          },
        },
        registrationType:[],
        compiencePoint:[],
        companyType:[],
        companyName:[],
        provinsi:[],
        kabupatenKota:[],
        regency:[]
      },
      form: {
        umum: {
          compName: null,
          compNameID: null,
          compType: null,
          compCity: "",
          compProvince: "",
        },
      },
      isLoading: false,
      showDownloadModal:false,
      showDownloadModalCSV:false,
      // end of filter
  };
},
methods: {
  uploadFile(field){
      // ${defaults.baseURL}/file
      const fileInput = this.$refs[field];
      const file = fileInput.files[0];
      const formData = new FormData();
      formData.append('file', file);
      this.uploading = true
      API.post(`blobs/upload/public`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          let data = res.data.data
          let value = data.id
          // this.$set(this.userInput, field, value);
          this.feedBackUploadDokumenSHU = value
          // console.log(`${data}:`, this.userInput[field]);
          this.uploading = false;
        })
        .catch((err) => {
          this.uploading = false;
          Swal.fire(
                  "Terjadi Kesalahan",
                  "Mohon upload ulang kembali",
                  "error"
          );
        });
  },
  getInfoSensor(id) {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    API.get(`loggers/${id}` , {headers})
      .then(({data:content}) => {
        

        for (let i = 0; i < content.data.sensors.length; i++) {
            if (content.data.sensors[i].type == 'pH') {
              if (content.data.sensors[i].is_active) {
                this.phDisable = false
              }
            }

            if (content.data.sensors[i].type == 'COD') {
              if (content.data.sensors[i].is_active) {
                this.codDisable = false
              }
            }

            if (content.data.sensors[i].type == 'TSS') {
              if (content.data.sensors[i].is_active) {
                this.tssDisable = false
              }
            }

            if (content.data.sensors[i].type == 'NH3N') {
              if (content.data.sensors[i].is_active) {
                this.nh3nDisable = false
              }
            }

            if (content.data.sensors[i].type == 'Debit') {
              if (content.data.sensors[i].is_active) {
                this.debitDisable = false
              }
            }
        }

         
      })
      .catch((err) => {
        Swal.fire('Terjadi Kesalahan','mohon maaf sensor gagal dimuat','error')
        console.log(err);
      })
  },
  getData() {
    this.isLoading = true
    let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    API.get(`manual-reports?page=${this.pagination.currentPage}&page_size=${this.pagination.perPage}` , {headers})
      .then(({data}) => {
        if (data.data == null) {
            this.listData = []
            this.isLoading = false
          } else {
            this.listData = data.data
            this.pagination.total = data.meta.pagination.total_items
            this.isLoading = false
          }

      })
      .catch((err) => {
        Swal.fire('Gagal mendapatkan Data','Sistem gagal menampilkan data, terjadi kesalahan','error')
        this.isLoading = false
      })
  },
  getCompanyType() {
  let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
  }

  API.get('comp-types', {headers})
    .then(({data:content}) => {
      this.provideSelect.companyType = content.data
    })
    .catch((err) => {
      console.log(err);
    })
  },
  getCompanyName() {
  let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
  }

  API.get('comps', {headers})
    .then(({data:content}) => {
      this.provideSelect.companyName = content.data
    })
    .catch((err) => {
      console.log(err);
    })
  },
  getProvince() {
  API.get(`provinces`)
    .then(({ data: content }) => {
      this.provideSelect.provinsi = content.data;
    })
    .catch((err) => {
      console.log(err);
    });
  },
  getMyCompliencePoint() {
      let headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }

      API.get(`stations?comp_id=${this.userInput.companyName}` , {headers})
      .then(({data:content}) => {
      this.userInput.titikPenaatan = ""
        this.listDataCompliencePoints = content.data
      })
      .catch((err) => {
        console.log(err);
      })
  },
  getCityDepentOnProvince(data) {
    this.form.umum.compCity = []
    this.provideSelect.kabupatenKota = [];
    API.get(`kabkots?prov_id=${data}`)
      .then(({ data: content }) => {
        this.provideSelect.kabupatenKota = content.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getBaseOnStatus() {
  this.isLoading = true

  let tmpDate = this.userInput.tanggal.split("to")

  let startDate = tmpDate[0]
  let endDate = tmpDate[1]


  if (endDate == undefined) {
    endDate = startDate
  } else if (endDate) {
    endDate.trim()
    startDate.trim()
  }

  const queryParams = this.queryParams();


  let headers = {
  Authorization: `Bearer ${localStorage.getItem('token')}`
  }


  

    const params = Object.fromEntries(
      Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
    );


  API.get(`manual-reports` , { params, headers})
    .then(({data}) => {
      if (data.data == null) {
          this.listData = []
          this.isLoading = false
        } else {
          this.listData = data.data
          this.pagination.total = data.meta.pagination.total_items
          this.isLoading = false
        }
    })
    .catch((err) => {
      this.isLoading = false
      console.log(err);
    })
  },
  queryParams(){
    let tmpDate = this.userInput.tanggal.split(" to ");

    let startDate = tmpDate[0];
    let endDate = tmpDate[1];

    if (endDate == undefined) {
      endDate = startDate;
    } else if (endDate) {
      endDate.trim();
      startDate.trim();
    }

    const queryParams = {
      page: this.pagination.currentPage,
      page_size: this.pagination.perPage,
      status: this.filterStatusPendaftaran,
      province_id: this.form.umum.compProvince,
      start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
      end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
      kabkot_id: this.form.umum.compCity,
      comp_id: this.userInput.companyName,
      comp_type_id: this.userInput.companyType,
      station_id: this.userInput.titikPenaatan,
    };
    return queryParams
  },
  getDataDownload() {
    this.isLoading = true

    let tmpDate = this.userInput.tanggal.split("to")

    let startDate = tmpDate[0]
    let endDate = tmpDate[1]


    if (endDate == undefined) {
      endDate = startDate
    } else if (endDate) {
      endDate.trim()
      startDate.trim()
    }


    let headers = {
    Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    const queryParams = {
      is_export: true,
      status: this.filterStatusPendaftaran,
      province_id: this.form.umum.compProvince,
      start_date: (startDate ? moment(startDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
      end_date: (endDate ? moment(endDate, "YYYY-MM-DD HH:mm").local().format("YYYY-MM-DD HH:mm:ss ZZ") : ""),
      kabkot_id: this.form.umum.compCity,
      comp_id: this.userInput.companyName,
      comp_type_id: this.userInput.companyType,
      station_id: this.userInput.titikPenaatan,
    };

    const params = Object.fromEntries(
        Object.entries(queryParams).filter(([key, value]) => value !== undefined && value !== null && value !== "")
      );


      API.get(`manual-reports` , { params, headers})
        .then(({data}) => {
          if (data.data == null) {
              this.listDataDownload = []
              this.isLoading = false
            } else {
              this.listDataDownload = data.data
              this.isLoading = false
            }
        })
        .catch((err) => {
          this.isLoading = false
          console.log(err);
        })
  },
  getCompliencePoint() {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    API.get(`stations` , {headers})
    .then(({data:content}) => {
      this.listDataCompliencePoints = content.data
    })
    .catch((err) => {
      console.log(err);
    })
  },
  // new
  isOneWeek() {
    console.log("TES");
  },
  downloadPDFNow() {
    this.getDataDownload()
    this.showDownloadModal = true      
  },
  downloadExcelNow() {
    this.getDataDownload()
    this.showDownloadModalCSV = true
  },
  print() {
    var element = document.getElementById("printBos");
    var opt = {
      margin: 0.5,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
    }
    // New Promise-based usage:
    html2pdf().set(opt).from(element).save();
    Swal.fire('Data di Unduh','','success')
    this.showDownloadModal = false
  },
  ExportExcel(type, fn, dl) {
      var elt = this.$refs.exportable_table;
      var wb = XLSX.utils.table_to_book(elt, { sheet: "Sheet JS" });
      Swal.fire('Data di Unduh','','success')
      this.showDownloadModalCSV = false
      return dl ?
          XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }) :
          XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
  },
  reportTableState() {
      return []
  },
   // End of Filter
  validationAction(id) {
    this.getRiwayatValidasi(id)
    this.getIdValidation = id
    this.showAnnoncementModalValidation = true
  },
  accSparing(command) {
    Swal.fire({
          title: "Konfimasi Tindakan",
          text: "Validasi data ini ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "VALIDASI",
          cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          let payload = {
            keterangan: this.verifyNote,
            status: command
          }

          API.put(`manual-reports/${this.getIdValidation}/approval` , payload, {headers})
            .then(({data:content}) => {
              if (command == 'Diterima') {
                Swal.fire('Validasi Berhasil diterima', 'Data ini sudah divalidasi', 'success')
              } else {
                Swal.fire('Validasi Berhasil ditolak', 'Data ini tidak lulus dalam proses validasi', 'success')
              }
              this.getData()
              this.verifyNote = ""
              this.showAnnoncementModalValidation = false
            })
            .catch((err) => {
              console.log(err);
              Swal.fire('Validasi Gagal', 'Data gagal diverifikasi', 'error')
            })

          
        }
      });
  },
  
  openModalAdd() {
    this.showAddModal = true
  },
  openModalUpdate() {
    this.showUpdateModal = true
  },
  openModalView() {
    this.showViewModal = true
  },
  closeModalAdd() {
    this.resetData()
    this.codDisable = true
    this.phDisable = true
    this.tssDisable = true
    this.nh3nDisable = true
    this.debitDisable = true
    this.showAddModal = false
  },
  closeModalUpdate() {
    this.showUpdateModal = false
  },
  closeModalView() {
    this.showViewModal = false
  },
  search() {

  },
  
  getStyleObject(val) {
      let backgroundColor;
      switch (val) {
        case "Menunggu Validasi":
          backgroundColor = "rgb(245 158 11)";
          break;
        case "Diterima":
          backgroundColor = "rgb(20 83 45)";
          break;
        case "Ditolak":
          backgroundColor = "#dc3545";
          break;
        default:
          backgroundColor = "white";
      }

      return {
        backgroundColor,
        // Anda dapat menambahkan properti CSS lainnya di sini
      };
  },
  getStatus(val) {
      if (val == 1 ) {
        return "Disetujui"
      } else if (val == 2) {
        return "Ditolak"
      } else {
        return "Menunggu"
      }
  },
  getRiwayatValidasi(id) {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }


    API.get(`report/weekly-manual/histories?report_weekly_manual_id=${id}` , {headers})
    .then(({data:content}) => {

      if (content.data == null) {
        this.validationHistory = []
      } else {
        this.validationHistory = content.data
      }
    })
    .catch((err) => {
      console.log(err);
    })
  },
  changeSizePage() {
    this.getData()
  },
  paginationChanged(page) {
    this.pagination.currentPage = page;
    this.getData()
  },
  view(id, command) {
    // this.getRiwayatValidasi(item.id)



    let headers = {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    API.get(`manual-reports/${id}` , {headers})
      .then(({data:content}) => {
       
        this.detailDateStart = content.data.start_date
        this.detailDateEnd = content.data.end_date

        this.detailView = {
          id:content.data.id,
          cod: content.data.parameter.COD,
          debit: content.data.parameter.Debit,
          nh3n: content.data.parameter.NH3N,
          ph: content.data.parameter.pH,
          tss: content.data.parameter.TSS,
          tanggalKejadian: content.data.start_date,
          tanggalAkhirKejadian: content.data.end_date,
          file:content.data.shu_file_id,
          loggerId: content.data.logger_id,
          status:content.data.status,
          company_name: content.data.comp_id
        }

        if (command == 'update') {
          this.phDisable = true
          this.codDisable = true
          this.tssDisable = true
          this.nh3nDisable = true
          this.debitDisable = true
          this.getInfoSensor(content.data.logger_id)
        }
      })
      .catch((err) => {
        console.log(err);
      })

      if (command == 'update') {
        this.openModalUpdate()
      } else {
        this.openModalView()
      }

   
  },
  deleteData(id) {
    Swal.fire({
          title: "Konfimasi Tindakan",
          text: "Anda yakin ingin menghapus data",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "HAPUS DATA",
          cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          API.delete(`manual-reports/${id}`, {headers})
          .then(({data}) => {
            this.getData()
            Swal.fire({title: "Berhasil Menghapus data", text: "",icon: "success"});
            })
            .catch((err) => {
              console.log(err,'ini error');
              Swal.fire("Data gagal dihapus", 'harap telah mengisi semua data atau periksa koneksi internet anda', "error");
            })
        }
      });
  },
  resetData() {
     this.payloadForm = new FormData(),
     this.userInput = {
      pilihTitikPenaatan: "",
      tanggalKejadian: "",
      ph:"0",
      cod:"0",
      tss:"0",
      nh3n:"0",
      debit:"0"
    }
  },
  submitNow() {
    let rangeDate = this.userInput.tanggalKejadian.split('to')

          let startDate = rangeDate[0].replace(/\s+$/, '')
          let endDate = rangeDate[1].trim()

          console.log(startDate,'start');
          console.log(endDate,'end');

    Swal.fire({
          title: "Konfimasi Tindakan",
          text: "Anda yakin ingin menambahkan data",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "KIRIM DATA",
          cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true

          

          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }

          let payload = {
            end_date: endDate,
            logger_id: this.userInput.pilihTitikPenaatan,
            parameter: {
              COD: +this.userInput.cod,
              Debit: +this.userInput.debit,
              NH3N: +this.userInput.nh3n,
              TSS: +this.userInput.tss,
              pH: +this.userInput.ph
            },
            shu_file_id: this.this.feedBackUploadDokumenSHU,
            start_date: startDate,
            status: 1
          }

          API.post('manual-reports' , payload, {headers})
          .then(({data}) => {
              this.resetData()
              this.feedBackUploadDokumenSHU = ""
              this.closeModalAdd()
              Swal.fire({title: "Berhasil Menambahkan", text: "",icon: "success"});
              this.getData()
            })
            .catch((err) => {
              Swal.fire("Kirim data gagal", 'harap telah mengisi semua data atau periksa koneksi internet anda', "error");
              this.isLoading = false
            })

        }
      });
  },
  submitUpdate() {
    let rangeDate = this.detailView.tanggalKejadian.split('to')

    let startDate = this.detailView.tanggalKejadian
    let endDate = this.detailView.tanggalAkhirKejadian

    if (rangeDate.length > 1) {
       startDate = rangeDate[0].replace(/\s+$/, '')
       endDate = rangeDate[1].trim()
    }

    console.log(startDate);
    console.log(endDate);

    Swal.fire({
          title: "Konfimasi Tindakan",
          text: "Anda yakin ingin merubah data",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "UBAH DATA",
          cancelButtonText: "BATAL"
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          let headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }


          let payload = {
            end_date: endDate,
            logger_id: this.detailView.loggerId,
            parameter: {
              COD: +this.detailView.cod,
              Debit: +this.detailView.debit,
              NH3N: +this.detailView.nh3n,
              TSS: +this.detailView.tss,
              pH: +this.detailView.ph
            },
            shu_file_id: "7757057a-4b22-4ea6-9547-ff415fed6357",
            start_date: startDate,
            status: 1
          }

          API.put(`manual-reports/${this.detailView.id}` , payload, {headers})
          .then(({data}) => {
            this.resetData()
            this.closeModalUpdate()
            Swal.fire({title: "Berhasil Merubah Data", text: "",icon: "success"});
            this.getData()
            })
            .catch((err) => {
              Swal.fire("Perubahan data gagal", 'harap telah mengisi semua data atau periksa koneksi internet anda', "error");
              this.isLoading = false
            })

        }
      });

  },
  formatDate(date) {
      const reformat = moment(date).format('DD-MM-YYYY')
      return reformat;
    },
  getQuarter(date) {
      let dateString = moment(date).format('YYYY-MM-DD')

      var firstDayOfMonth = moment(date).startOf('month');

      // Menghitung selisih hari antara tanggal dan hari pertama bulan ini
      var dayDiff = moment(date).date() - firstDayOfMonth.date();

      // Menghitung minggu keberapa berdasarkan selisih hari
      var weekNumber = Math.ceil((dayDiff + firstDayOfMonth.day() + 1) / 7);

      return weekNumber;
  },
  
},
};
</script>

<style scoped>

.mdl-btn {
  border-radius: 4px;
  padding: 6px 12px;
  border: transparent;
  color: #ffffff;
  width: 50%;
  margin-right: 0;
}
.mdl-btn-accept {
  background-color: #3D9973;
  
}
.mdl-btn-reject {
  background-color: #EB5757;
}

.mdl-title {
  font-size: 18px;
  font-weight: 600;
  color: #6C757D;
  width: 100%;
}
.mdl-contents {
  overflow-y: auto;
  max-height: 90%;
}
.mdl-header {
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  column-gap: 1rem;
}
.mdl-header i {
  font-size: 30px;
  color: #00bcd4;
  color: #FFFFFF;
}
.mdl-header p {
  margin-bottom: 0;
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
}

.announcement-modal-backgrounds {
  background: #ff9999;
}

  </style>
  
  